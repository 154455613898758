import React, { Component } from "react";
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import logo from "./intellichoice_bubble.png";

import Profile from "./components/profile.component";
import RegisterAccount from "./components/register.component";
import VerifyEmail from "./components/verify-email.component";
import ForgotPassword from "./components/forgot-password.component";
import Login from "./components/login.component";
import Logout from "./components/logout.component";
import NavItem from "./nav-item";
import Welcome from "./components/welcome.component";
import Signups from "./components/signups.component";
import ResetPassword from "./components/reset-password.component";
import Attendance from "./components/attendance.component";
import Chapter from "./components/chapter.component";
import Reports from "./components/reports.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.loggedIn = this.loggedIn.bind(this);
    this.state = {
      token: window.sessionStorage.getItem("token"),
      role: window.sessionStorage.getItem("role"),
      name: window.sessionStorage.getItem("name"),
      chapterRole: window.sessionStorage.getItem("chapterRole")
    };
    console.log(this.state.token);
  }
  loggedIn(data) {
    console.log("logged in");
    console.log(data);
    window.sessionStorage.setItem("token", data.token);
    window.sessionStorage.setItem("role", data.role);
    window.sessionStorage.setItem("name", data.name);
    window.sessionStorage.setItem("chapterRole", data.chapterRole);
    this.setState({ token: data.token, role: data.role, name: data.name, chapterRole: data.chapterRole })
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container-fluid">
            <a href="https://intellichoice.org" className="navbar-brand">
              <img src={logo} className="logo" alt="Logo" />
              <span>IntelliChoice</span>
            </a>
            {this.state.token && <span className="navbar-text">Welcome, {this.state.name}!</span>}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {!this.state.token && <NavItem to="/register" text="Register" />}
                {!this.state.token && <NavItem to="/login" text="Login" />}
                {this.state.token && (this.state.role === "Manager") && <NavItem to="/reports" text="Reports" />}
                {this.state.token && (this.state.chapterRole !== null) && <NavItem to="/chapter" text="Chapter Mgmt" />}
                {this.state.token && (this.state.role === "Manager") && <NavItem to="/attendance" text="Branch Mgmt" />}
                {this.state.token && <NavItem to="/signups" text="Sessions" />}
                {this.state.token && <NavItem to="/profile" text="Profile" />}
                {this.state.token && <NavItem to="/logout" text="Logout" />}
              </ul>
              <span class="navbar-text">v2.5.0</span>
            </div>
          </div>
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/register" element={<RegisterAccount />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/login" element={<Login loggedIn={this.loggedIn} />} />
            <Route path="/logout" element={<Logout loggedIn={this.loggedIn} />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/signups" element={<Signups />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/chapter" element={<Chapter />} />
            <Route path="/reports" element={<Reports />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
